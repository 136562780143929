import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Link,
  Switch,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import Title from '../components/Title/Title';
import Avatar from '../components/Avatar/Avatar';
import Input from '../components/Input/Input';
import Button from '../components/Button/Button';
// import useSocket from '../hooks/useSocket';
import SocketContext from '../context/socket-context';
import AvatarUploader from '../components/AvatarUploader';
import { useDispatch, useSelector } from 'react-redux';
import computeUserTitles from 'utils/computeUserTitles';
import ChangeUserTitle from 'components/UserTitle/ChangeUserTitle';
import { updateUserState } from 'redux/features/userSlice';
import NewTitleModal from 'components/Modal/NewTitleModal';
import Rooms from 'components/Rooms';
import { isEasterEggAvatar } from 'constants/easterEggAvatars';
import { updateUser } from 'myFirebase/user';
import useLocalStorage from 'hooks/useLocalStorage';

function Home({ join = false }) {
  const [showDemo, setShowDemo] = useLocalStorage('showDemo', true);
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const { gameId } = useParams();
  const [isPublic, setIsPublic] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [titles, setTitles] = useState([]);


  useEffect(() => {
    const handleNewUserTitles = tmpTitles => {
      setTitles(tmpTitles || []);
      if (tmpTitles) {
        onOpen();
        dispatch(
          updateUserState({ titles: [...(user?.titles || []), ...tmpTitles] })
        );
      }
    };
    if (!user || !dispatch || isOpen || titles.length) return;
    setName(user.name);
    computeUserTitles(user, handleNewUserTitles);
  }, [user, dispatch, isOpen, titles, onOpen]);

  const handleJoin = (currentGameId) => {
      socket.emit('joinGame', {
        name: name || "guest",
        avatar: isEasterEggAvatar(name) || user?.avatar,
        title: user?.currentTitle,
        gameId: currentGameId,
      });
    navigate('/lobby');
  }

  const handleStart = () => {
    if (user && user?.ref && user.name !== name) {
      console.log("persist", name)
      updateUser(user.ref, {name});
    }
    if (join) {
      handleJoin(gameId)
      return
    } else {
      socket.emit(
        'createGame',
        name,
        isEasterEggAvatar(name) || user?.avatar,
        user?.currentTitle,
        isPublic
      );
    }
    navigate('/lobby');
  };

  if (showDemo) return <DemoGame setShowDemo={setShowDemo}/>
  return (
    <Flex
      direction="column"
      justify="space-around"
      align="center"
      minH="80vh"
      w="100vw"
    >
      <Title>Cozy Surviving</Title>
      <HStack flexWrap="wrap" justify="space-around">
        {/* in case server is almost full */}
        {process.env.REACT_APP_PREMIUM_ONLY && !user?.premium  && !join ? <VStack w="100%" p="5" textAlign="center">
          <Text fontSize="2xl">Merci infiniment de votre intérêt pour Cozy surviving.</Text><Text fontSize="xl" fontWeight="bold">Pour économiser les ressources limitées du serveur, désormais seuls les membres premium peuvent créer des parties.</Text><Text fontSize={"xl"}>N'hésitez pas à rejoindre le discord pour rester informer !</Text>
        </VStack> : (<Flex p="5" direction="column" align="center">
          <AvatarUploader userId={user?.ref} isPremium={user?.premium} />
          <ChangeUserTitle />
          <Avatar forceAvatar={isEasterEggAvatar(name) || user?.avatar} name={name} />
          <form onSubmit={handleStart}>
            <Input
              m={2}
              autoFocus
              w="100%"
              placeholder="Username"
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              maxLength={20}
            />
            {!join && (
              <FormControl
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <FormLabel
                  fontWeight="bold"
                  fontSize="lg"
                  htmlFor="isPublic"
                  mb="0"
                >
                  Créer une partie publique
                </FormLabel>
                <Switch
                  onChange={() => setIsPublic(!isPublic)}
                  isChecked={isPublic}
                  name="isPublic"
                  id="isPublic"
                />
              </FormControl>
            )}
            <Button type="submit" disabled={!name} w="100%">
              {join ? 'Rejoindre' : 'Créer une partie'}
            </Button>
          </form>
        </Flex>)}
        <VStack maxW="80vw" p="5">
          <JoinDiscord />
          {!join && <Rooms handleJoin={handleJoin} canJoin={!!name} />}
        </VStack>
      </HStack>
      <NewTitleModal onClose={onClose} isOpen={isOpen} titles={titles} />
    </Flex>
  );
}

function JoinDiscord() {
  return (
    <Box
      textAlign="center"
      padding="4"
      border="1px"
      borderRadius="md"
      maxWidth="400px"
      margin="auto"
      backgroundColor="#f0f0f0"
    >
      <Image
        maxH={'10vh'}
        src="./images/logo-discord.png"
        alt="Discord Logo"
        mb="2"
      />
      <Text>
        Rejoignez le serveur Discord pour jouer avec la communauté, suivre les
        mises à jour et donner vos retours !
      </Text>
      <Link
        href="https://discord.gg/TvDCnEU5gf"
        target="_blank"
        rel="noopener noreferrer"
        color="blue.500"
        fontWeight="bold"
        mt="2"
        display="block"
      >
        Rejoindre Discord
      </Link>
    </Box>
  );
}


const DemoGame = ({setShowDemo}) => {
  const [name, setName] = useState('Nouveau Joueur');
  const socket = useContext(SocketContext);
  const navigate = useNavigate();

  const handleStartTutorial = () => {
    console.log('Tutoriel démarré');
      socket.emit(
        'demoGame',
        name,
      );
    navigate('/start');
    setShowDemo(false)
  };

  const handleSkipTutorial = () => {
    setShowDemo(false)
  };

  return (
    <Flex direction="column" justify="center" align="center" minH="80vh" w="100vw" p={5}>
      <VStack spacing={8} w="full" maxW="fit-content" >
        <Avatar forceAvatar={null} name={name} />
        <Input
          m={2}
          autoFocus
          w="full"
          fontSize="2xl"
          placeholder="Username"
          value={name}
          onChange={e => setName(e.target.value)}
          maxLength={20}
          borderColor="teal.500"
          focusBorderColor="teal.300"
          boxShadow="sm"
        />
        <VStack w="full">
          <Button
            onClick={handleStartTutorial}
            bg="sea"
            color="white"
            fontSize="3xl"
          >
            Commencer l'aventure !
          </Button>
          <Button
            onClick={handleSkipTutorial}
            fontSize="md"
            p="6"
          >
            Ignorer le tutoriel
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
};

export default Home;